// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as $$Date from "../../../../libs/Date.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Picture from "../../../../styleguide/forms/Picture/Picture.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as Project from "../../../../models/Project.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconEdit from "../../../../styleguide/icons/IconEdit.res.js";
import * as IconFile from "../../../../styleguide/icons/IconFile.res.js";
import * as IconYear from "../../../../styleguide/icons/IconYear.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconBuilding from "../../../../styleguide/icons/IconBuilding.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as IconArrowLeft from "../../../../styleguide/icons/IconArrowLeft.res.js";
import * as IconEmployees from "../../../../styleguide/icons/IconEmployees.res.js";
import * as IconFinalized from "../../../../styleguide/icons/IconFinalized.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import Format from "date-fns/format";
import * as TourRequestModal from "../../../../styleguide/modals/TourRequestModal/TourRequestModal.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as TourRequestSentModal from "../../../../styleguide/modals/TourRequestModal/TourRequestSentModal.res.js";
import * as ProjectProposalStatusDropdown from "../_components/ProjectProposalStatusDropdown/ProjectProposalStatusDropdown.res.js";
import * as UserProjectConciergesInformation from "../_components/UserProjectDetailsContactInformation/UserProjectConciergesInformation.res.js";
import * as UserProjectProposal_DetailsScss from "./UserProjectProposal_Details.scss";
import * as UserProjectProposalProviderManagersInformation from "../_components/UserProjectDetailsContactInformation/UserProjectProposalProviderManagersInformation.res.js";

var css = UserProjectProposal_DetailsScss;

function reducer(state, action) {
  if (typeof action !== "object") {
    return {
            proposal: state.proposal,
            isHistoryShown: !state.isHistoryShown
          };
  } else {
    return {
            proposal: action._0.proposal,
            isHistoryShown: state.isHistoryShown
          };
  }
}

function UserProjectProposal_Details(props) {
  var role = props.role;
  var viewContext = props.viewContext;
  var userRole = props.userRole;
  var provider = props.provider;
  var documents = props.documents;
  var additionalDocuments = props.additionalDocuments;
  var proposal = props.proposal;
  var managers = props.managers;
  var projectId = props.projectId;
  var currentDocument = Belt_Array.get(documents, 0);
  var match = React.useReducer(reducer, {
        proposal: proposal,
        isHistoryShown: false
      });
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return "Hidden";
      });
  var setTourRequestModalVisibility = match$1[1];
  var isCanceledOrNoBid = React.useMemo((function () {
          var match = state.proposal.status;
          if (match === "Preparing" || match === "NoBid") {
            return true;
          } else {
            return match === "Canceled";
          }
        }), [state.proposal]);
  var match$2 = proposal.status;
  var tourButton = JsxRuntime.jsx(Button.make, {
        size: "MD",
        color: "Gray",
        visuallyDisabled: !(match$2 === "Preparing" || match$2 === "InProgress"),
        buttonClassName: proposal.status === "Preparing" ? css.tourButtonPreparing : css.tourButton,
        onClick: (function (param) {
            setTourRequestModalVisibility(function (param) {
                  return "Shown";
                });
          }),
        children: JsxRuntime.jsx("span", {
              children: "Request Tour"
            })
      });
  var updateProposalStatus = function (projectId, proposal, message, param) {
    $$Promise.wait(Api.updateProjectProposalStatus(projectId, proposal, message, undefined), (function (x) {
            if (x.TAG === "Ok") {
              return dispatch({
                          TAG: "UpdateProposalStatus",
                          _0: x._0
                        });
            } else {
              return SentryLogger.error1({
                          rootModule: "UserProjectProposal_Details",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "UserProjectProposal_Details.make"
                        }, "ProposalDetails::UpdateStatus::Error", [
                          "Error",
                          x._0
                        ]);
            }
          }));
  };
  var category = Project.Category.fromString(proposal.projectCategory, proposal.projectSubcategory);
  var tmp;
  switch (userRole) {
    case "Provider" :
        tmp = props.manageMultipleProviders ? JsxRuntime.jsx("div", {
                children: JsxRuntime.jsxs("a", {
                      children: [
                        JsxRuntime.jsx(IconArrowLeft.make, {
                              size: "SM",
                              color: "GrayText"
                            }),
                        JsxRuntime.jsx("span", {
                              children: "Back to All Proposals"
                            })
                      ],
                      href: Routes_Project.Proposal.index(projectId)
                    }),
                className: css.goBack
              }) : null;
        break;
    case "Visitor" :
        tmp = null;
        break;
    default:
      tmp = JsxRuntime.jsx("div", {
            children: JsxRuntime.jsxs("a", {
                  children: [
                    JsxRuntime.jsx(IconArrowLeft.make, {
                          size: "SM",
                          color: "GrayText"
                        }),
                    JsxRuntime.jsx("span", {
                          children: "Back to All Proposals"
                        })
                  ],
                  href: Routes_Project.Proposal.index(projectId)
                }),
            className: css.goBack
          });
  }
  var match$3 = proposal.status;
  var match$4 = proposal.status;
  var tmp$1;
  if (category.TAG === "Colocation") {
    if (proposal.tourRequested) {
      tmp$1 = JsxRuntime.jsxs(Button.make, {
            size: "MD",
            color: "Gray",
            buttonClassName: proposal.status === "Preparing" ? css.tourSentButtonPreparing : css.tourSentButton,
            onClick: (function (param) {
                setTourRequestModalVisibility(function (param) {
                      return "Shown";
                    });
              }),
            children: [
              JsxRuntime.jsx(IconFinalized.make, {
                    size: "LG",
                    color: "White"
                  }),
              JsxRuntime.jsx("span", {
                    children: "Tour Requested"
                  })
            ]
          });
    } else {
      switch (viewContext) {
        case "Personal" :
            switch (role) {
              case "Provider" :
              case "Visitor" :
                  tmp$1 = null;
                  break;
              default:
                tmp$1 = tourButton;
            }
            break;
        case "Admin" :
            switch (role) {
              case "Provider" :
              case "Visitor" :
                  tmp$1 = null;
                  break;
              default:
                tmp$1 = tourButton;
            }
            break;
        case "Visitor" :
            tmp$1 = null;
            break;
        
      }
    }
  } else {
    tmp$1 = null;
  }
  var tmp$2;
  tmp$2 = category.TAG === "Colocation" && match$1[0] === "Shown" ? (
      state.proposal.tourRequested ? JsxRuntime.jsx(TourRequestSentModal.make, {
              close: (function () {
                  setTourRequestModalVisibility(function (param) {
                        return "Hidden";
                      });
                }),
              providerName: proposal.providerName,
              tourDate: proposal.tourDate,
              message: proposal.statusMessage
            }) : JsxRuntime.jsx(TourRequestModal.make, {
              close: (function () {
                  setTourRequestModalVisibility(function (param) {
                        return "Hidden";
                      });
                }),
              projectId: projectId,
              proposalId: proposal.id
            })
    ) : null;
  var match$5 = proposal.status;
  var tmp$3;
  switch (userRole) {
    case "User" :
    case "Visitor" :
    case "Agent" :
        tmp$3 = null;
        break;
    default:
      var date = state.proposal.lastViewedAt;
      tmp$3 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: "Client last viewed",
                            className: css.proposalLabel
                          }),
                      JsxRuntime.jsx("div", {
                            children: date !== undefined ? $$Date.Naive.format(Caml_option.valFromOption(date), "dd MMM yyyy") : "",
                            className: css.dateValue
                          })
                    ],
                    className: Cx.cx([
                          css.proposalDate,
                          css.providerClientDate
                        ])
                  }),
              JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx(IconEdit.make, {
                                  size: "LG",
                                  color: isCanceledOrNoBid ? "LighterGray" : "Teal"
                                }),
                            JsxRuntime.jsx("span", {
                                  children: "Edit Proposal"
                                })
                          ],
                          className: Cx.cx([
                                css.proposalEditButton,
                                isCanceledOrNoBid ? css.disabled : ""
                              ]),
                          id: "editProposalButton",
                          onClick: (function (param) {
                              if (!isCanceledOrNoBid) {
                                return Url.visit(Routes_Project.Proposal.update(projectId, proposal.id));
                              }
                              
                            })
                        }),
                    className: css.proposalActions
                  })
            ]
          });
  }
  var tmp$4;
  switch (userRole) {
    case "User" :
    case "Visitor" :
        tmp$4 = null;
        break;
    default:
      tmp$4 = managers.length !== 0 ? JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(UserProjectProposalProviderManagersInformation.make, {
                    managers: managers
                  }),
              className: Cx.cx([
                    css.sideBlock,
                    css.condensedSideBlock
                  ])
            }) : null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        tmp,
                        JsxRuntime.jsx("h2", {
                              children: provider.name + " Proposal",
                              className: css.title
                            }),
                        match$3 === "Preparing" ? JsxRuntime.jsx("p", {
                                children: proposal.statusMessage,
                                className: css.providerDesc
                              }) : JsxRuntime.jsx("p", {
                                children: state.proposal.description,
                                className: css.proposalDescription
                              }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        match$4 === "Finalized" || match$4 === "NoBid" || match$4 === "Canceled" || match$4 === "Expired" ? JsxRuntime.jsxs(Button.make, {
                                                size: "MD",
                                                color: "Primary",
                                                disabled: true,
                                                visuallyDisabled: true,
                                                children: [
                                                  JsxRuntime.jsx(IconFile.make, {
                                                        size: "SM",
                                                        color: "White",
                                                        className: css.proposalSvg
                                                      }),
                                                  "Download Proposal"
                                                ]
                                              }) : (
                                            match$4 === "Preparing" ? null : JsxRuntime.jsxs(Popover.make, {
                                                    children: [
                                                      JsxRuntime.jsx(Popover.Trigger.make, {
                                                            className: css.downloadButton,
                                                            children: JsxRuntime.jsxs(Button.make, {
                                                                  size: "MD",
                                                                  color: "Primary",
                                                                  children: [
                                                                    JsxRuntime.jsx(IconFile.make, {
                                                                          size: "SM",
                                                                          color: "White",
                                                                          className: css.proposalSvg
                                                                        }),
                                                                    "Download Proposal"
                                                                  ]
                                                                })
                                                          }),
                                                      JsxRuntime.jsxs(Dropdown.Body.make, {
                                                            position: {
                                                              TAG: "Below",
                                                              _0: "RightEdge"
                                                            },
                                                            className: css.newProjectMenu,
                                                            children: [
                                                              JsxRuntime.jsx("div", {
                                                                    children: JsxRuntime.jsx(Control.make, {
                                                                          className: css.newProjectMenuItemControl,
                                                                          onClick: (function (param) {
                                                                              Url.visit(Routes_Project.Proposal.download(projectId, proposal.id));
                                                                            }),
                                                                          children: "Download Current Proposal"
                                                                        }),
                                                                    className: css.newProjectMenuItem
                                                                  }),
                                                              additionalDocuments.length !== 0 ? JsxRuntime.jsx("div", {
                                                                      children: JsxRuntime.jsx(Control.make, {
                                                                            className: css.newProjectMenuItemControl,
                                                                            onClick: (function (param) {
                                                                                Url.visit(Routes_Project.Proposal.downloadAllAdditional(projectId, proposal.id));
                                                                              }),
                                                                            children: "Download Proposal & Documents"
                                                                          }),
                                                                      className: css.newProjectMenuItem
                                                                    }) : null,
                                                              JsxRuntime.jsx("div", {
                                                                    children: JsxRuntime.jsx(Control.make, {
                                                                          className: css.newProjectMenuItemControl,
                                                                          onClick: (function (param) {
                                                                              Url.visit(Routes_Project.Proposal.downloadAll(projectId, proposal.id));
                                                                            }),
                                                                          children: "Download All"
                                                                        }),
                                                                    className: css.newProjectMenuItem
                                                                  })
                                                            ]
                                                          })
                                                    ]
                                                  })
                                          ),
                                        tmp$1,
                                        tmp$2
                                      ],
                                      className: css.buttonContainer
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("p", {
                                              children: "Last time updated " + Format(state.proposal.updatedAt, "MMM dd, yyyy"),
                                              className: css.lastUpdated
                                            }),
                                        documents.length > 1 ? JsxRuntime.jsx("a", {
                                                children: (
                                                  state.isHistoryShown ? "Hide" : "View"
                                                ) + " History",
                                                className: css.historyLink,
                                                onClick: (function (param) {
                                                    dispatch("ToogleHistory");
                                                  })
                                              }) : null
                                      ]
                                    })
                              ],
                              className: css.proposalAndHistory
                            }),
                        documents.length > 1 && state.isHistoryShown ? JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: "Proposal History",
                                        className: css.historyTitle
                                      }),
                                  Belt_Array.map(documents, (function ($$document) {
                                          return JsxRuntime.jsxs("div", {
                                                      children: [
                                                        JsxRuntime.jsx(IconFile.make, {
                                                              size: "LG",
                                                              color: "DarkGray"
                                                            }),
                                                        JsxRuntime.jsx("span", {
                                                              children: (
                                                                currentDocument !== undefined && Caml_obj.equal(currentDocument.id, $$document.id) ? "Current " : ""
                                                              ) + ("Proposal from " + $$Date.Utc.format($$document.createdAt, "dd MMM, yyyy, h:mm a"))
                                                            }),
                                                        JsxRuntime.jsx(Button.make, {
                                                              size: "SM",
                                                              color: "Gray",
                                                              className: css.downloadHistory,
                                                              onClick: (function (param) {
                                                                  Url.visit(Routes_Project.Proposal.downloadHistory(projectId, proposal.id, $$document.id));
                                                                }),
                                                              children: "Download"
                                                            })
                                                      ],
                                                      className: css.historyRow
                                                    }, Project.DocumentId.toString($$document.id));
                                        }))
                                ],
                                className: css.history
                              }) : null,
                        additionalDocuments.length !== 0 ? JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: "Additional Documents",
                                        className: css.historyTitle
                                      }),
                                  Belt_Array.map(additionalDocuments, (function ($$document) {
                                          return JsxRuntime.jsxs("div", {
                                                      children: [
                                                        JsxRuntime.jsx(IconFile.make, {
                                                              size: "LG",
                                                              color: "DarkGray"
                                                            }),
                                                        JsxRuntime.jsx("span", {
                                                              children: $$document.fileName
                                                            }),
                                                        JsxRuntime.jsx(Button.make, {
                                                              size: "SM",
                                                              color: "Gray",
                                                              className: css.downloadHistory,
                                                              onClick: (function (param) {
                                                                  Url.visit(Routes_Project.Proposal.downloadAdditional(projectId, proposal.id, $$document.id));
                                                                }),
                                                              children: "Download"
                                                            })
                                                      ],
                                                      className: css.historyRow
                                                    }, Project.DocumentId.toString($$document.id));
                                        }))
                                ],
                                className: css.additional
                              }) : null
                      ],
                      className: css.colLeft
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Proposal Status",
                                      className: css.blockTitle
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(ProjectProposalStatusDropdown.make, {
                                            proposal: state.proposal,
                                            projectId: projectId,
                                            userViewContext: viewContext,
                                            iconSize: "XS",
                                            updateProposalStatus: updateProposalStatus,
                                            setBidModalVisibility: (function (prim) {
                                                
                                              })
                                          }),
                                      className: css.proposalStatus
                                    }),
                                match$5 === "Preparing" ? null : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                        children: [
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx("div", {
                                                        children: "Sent",
                                                        className: css.proposalLabel
                                                      }),
                                                  JsxRuntime.jsx("div", {
                                                        children: $$Date.Naive.format(state.proposal.sentAt, "dd MMM yyyy"),
                                                        className: css.dateValue
                                                      })
                                                ],
                                                className: css.proposalDate
                                              }),
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx("div", {
                                                        children: "Expires",
                                                        className: css.proposalLabel
                                                      }),
                                                  JsxRuntime.jsxs("div", {
                                                        children: [
                                                          proposal.almostExpired ? JsxRuntime.jsx("span", {
                                                                  children: "Soon!",
                                                                  className: css.soonBadge
                                                                }) : null,
                                                          JsxRuntime.jsx("span", {
                                                                children: $$Date.Naive.format(state.proposal.expiresAt, "dd MMM yyyy")
                                                              })
                                                        ],
                                                        className: css.dateValue
                                                      })
                                                ],
                                                className: css.proposalDate
                                              })
                                        ]
                                      }),
                                tmp$3
                              ],
                              className: css.sideBlock
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Picture.make, {
                                      src: provider.logo,
                                      large: [
                                        100,
                                        40
                                      ],
                                      crop: "Fit",
                                      imageClassName: css.providerLogo
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(A.make, {
                                            href: "/providers/" + provider.slug,
                                            targetBlank: true,
                                            children: provider.name
                                          }),
                                      className: css.blockTitle
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: provider.address,
                                      className: css.providerAddress
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(IconBuilding.make, {
                                              size: "SM",
                                              color: "DarkGray"
                                            }),
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx("span", {
                                                      children: String(provider.totalFacilities),
                                                      className: css.highlighted
                                                    }),
                                                " facilities"
                                              ]
                                            })
                                      ],
                                      className: css.iconAndData
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(IconEmployees.make, {
                                              size: "SM",
                                              color: "DarkGray"
                                            }),
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx("span", {
                                                      children: String(provider.numberEmployees),
                                                      className: css.highlighted
                                                    }),
                                                " employees"
                                              ]
                                            })
                                      ],
                                      className: css.iconAndData
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(IconYear.make, {
                                              size: "SM",
                                              color: "DarkGray"
                                            }),
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx("span", {
                                                      children: String(provider.yearFounded),
                                                      className: css.highlighted
                                                    }),
                                                " year of foundation"
                                              ]
                                            })
                                      ],
                                      className: css.iconAndData
                                    })
                              ],
                              className: css.sideBlock
                            }),
                        tmp$4,
                        JsxRuntime.jsx(UserProjectConciergesInformation.make, {
                              concierges: props.concierges
                            })
                      ],
                      className: css.colRight
                    })
              ],
              className: css.columnWrapper
            });
}

var make = UserProjectProposal_Details;

export {
  css ,
  reducer ,
  make ,
}
/* css Not a pure module */
